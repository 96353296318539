.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

body {
  margin: 0;
}

canvas {
  background-color: #232323;
}